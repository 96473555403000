//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import scrollTop from '@/lib/common-service/scrollTop'
import fligthInternationalList from '@/lib/data-service/flight/fligthInternationalAdmin/flight_international_buyer_manage_order_list.js'
import {internationalAirLines} from '@/lib/data-service/flight/fligthInternational'
import cancel from '@/lib/data-service/flight/fligthInternationalAdmin/flight_international_buyer_white_order_cancel'
import { resolve } from 'q';
export default {
  data() {
    return {
      searchForm: {
        currentPage:1,
        pageSize:10
      },
      totalCount:0,
      changeRecordList:[
        {text:'全部',value:''},
        {text:'待支付',value:11},
        {text:'待出票',value:21},
        {text:'出票成功',value:22},
        {text:'出票失败',value:23},
        {text:'已取消',value:90}
      ],
      NavItemActive:'',
      pageData:[],
      loading:false,
      airlinesList:[
        {
          airlineCode: '',
          airCompanyName: '不限',
          airCompanyEnglistName:'',
          airCompanyShortName:'',
          airlineCode:'',
          countryState:''
        }
      ]
    };
  },
  created(){
    this.search()
    this.getAirlines()
  },
  methods: {
    statuSearch(item,index){
       this.NavItemActive = index
       this.searchForm.orderStatus = item.value
       this.search()
    },
    searchClear() {

    },
    // 日期选择
    selectPickerOptions() {
      return {
        disabledDate: time => {
            return time.getTime() > Date.now()
        }
      }
    },
     // 获取航司列表
    getAirlines() {
      internationalAirLines().then(data => {
        // console.log(data)
        let airlinesList = [...this.airlinesList,...data.pageResult.pageData]
        this.airlinesList = airlinesList
      })
    },
    reset() {
      this.searchForm = {currentPage:1,pageSize:10}
    },
    search(pageNum) {
      let data = this.searchForm
      if(typeof pageNum == 'number'){
          data.currentPage = pageNum||1
      }else{
        data.currentPage = 1
      }
      data.orderTimeStart?data.orderTimeEnd = this.$moment().format('YYYY-MM-DD'):data.orderTimeEnd = ''
      fligthInternationalList(data).then(res=>{
        this.pageData = res.pageResult.pageData
        this.totalCount = res.pageResult.totalCount
        scrollTop(500)
      })
    },
    setOrdersNo(item,type){
      if(type==='pay'){
        this.$router.push({name:'international-pay',query:{ordersNo:item.orderNo,type:'international'},params:{refresh:1}})
      }else if(type==='cancel'){
        this._cancelOrder(item)
      }else{
         this.$router.push({name:'air-ticket-admin-international-list-detail',query:{ordersNo:item.orderNo,type:type,supplierType:item.supplierType}
         ,params:{refresh:1}})
      }
    },
    _cancelOrder(val){
      let data = {
        "orderId": val.orderNo,
        "pnr": ""
      }
       this._setPopPu('确定要取消订单吗？',()=>{
            cancel(data).then(res=>{
              if(res.isSuccess){
                 this.$message({type:"warning",message:"取消订单成功！"})
                 this.search()
              }
            })
        })
    },
    _setPopPu(text,callBack){
        const content = {
            text:text||''
        }
        this.$confirm(content.text, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                  return  callBack()
              }).catch(() => {})
    },
    handlerSize(val){
      this.searchForm.pageSize = val
      this.search()
    },
    handlerPage(val){
      this.search(val)
    }
  }
};
