const __request = require(`../__request/__request_contentType_json`);

// 国际机票后台取消
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/flight/international/buyer/white/order/cancel',
    data:data
  }

  return __request(pParameter)
}

